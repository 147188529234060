import Slider from 'bootstrap-slider';
import { submitAfterChange } from '../../../shared/libs/form/submit-on-change';
import { getInputNonFormattedValue, setInputFormattedValue } from './number-format-input';

export const initSliders = (sliderSelector) => {
    const sliders = document.querySelectorAll(sliderSelector);

    for (let i = 0; i < sliders.length; i++) {
        const sliderElement = sliders[i];

        const fromInput = document.querySelector(sliderElement.getAttribute('data-linked-input-from-selector'));
        const toInput = document.querySelector(sliderElement.getAttribute('data-linked-input-to-selector'));

        const config = {};

        const customSubmitCallback = () => {
            // Před odesláním odstraní z čísla speciální znaky
            // const from = form.querySelector(`input[name="${fromInput.getAttribute('name')}"]`);
            // const to = form.querySelector(`input[name="${toInput.getAttribute('name')}"]`);
            // from.value = from.value.replace(/[^0-9]/g, '');
            // to.value = to.value.replace(/[^0-9]/g, '');
        };

        if (fromInput !== null && toInput !== null) {
            // Nastaví výchozí hodnoty slideru
            config['value'] = [
                parseInt(fromInput.value.replace(/[^0-9]/g, '')),
                parseInt(toInput.value.replace(/[^0-9]/g, '')),
            ];
        }

        const slider = new Slider(sliderElement, config);

        slider.on('slide', (values) => {
            const range = values;

            if (fromInput !== null) {
                setInputFormattedValue(fromInput, range[0].toString());
            }

            if (toInput !== null) {
                setInputFormattedValue(toInput, range[1].toString());
            }
        });

        if (sliderElement.classList.contains('submit-on-change__trigger')) {
            slider.on('slideStop', () => {
                submitAfterChange(sliderElement, customSubmitCallback);
            });
        }

        fromInput.addEventListener('blur', (event) => {
            const values = slider.getValue();
            const newValue = getInputNonFormattedValue(event.target);

            if (event.target.value === '' || newValue > values[1] || newValue < slider.getAttribute('min')) {
                // Nahradí původní hodnotou, pokud je prázdné, nebo hodnota je větší než DO, nebo hodnota je menší než MIN
                setInputFormattedValue(event.target, values[0].toString());

                return false;
            } else if (newValue !== values[0]) {
                // Nastaví slideru novou hodnotu, pokud se liší od původní hodnoty
                slider.setValue([newValue, values[1]]);

                if (sliderElement.classList.contains('submit-on-change__trigger')) {
                    submitAfterChange(sliderElement, customSubmitCallback);
                }
            }
        });

        toInput.addEventListener('blur', (event) => {
            const values = slider.getValue();
            const newValue = getInputNonFormattedValue(event.target);

            if (event.target.value === '' || newValue < values[0] || newValue > slider.getAttribute('max')) {
                // Nahradí původní hodnotou, pokud je prázdné, nebo hodnota je menší než OD, nebo hodnota je větší než MAX
                setInputFormattedValue(event.target, values[1].toString());

                return false;
            } else if (newValue !== values[1]) {
                // Nastaví slideru novou hodnotu, pokud se liší od původní hodnoty
                slider.setValue([values[0], newValue]);

                if (sliderElement.classList.contains('submit-on-change__trigger')) {
                    submitAfterChange(sliderElement, customSubmitCallback);
                }
            }
        });

        sliderElement.dataset.sliderInstanceIndex = window.bootstrapSliders.push(slider) - 1;
    }
};
