import * as bootstrapModal from 'bootstrap/js/dist/modal';
import { constructUrl } from '../../../shared/js/utils/global-helpers';
import { refreshGrid } from '../../../shared/js/utils/grid';

/**
 * Priprava modalu na zavreni tlacitkem zpet
 * @param modal
 */
const setupModalForCloseByBackButton = (modal) => {
    // Nastavime jako hash ID modalu
    window.location.hash = modal._element.id;

    // Na zmenu hashe navesime zavreni modalu
    window.onhashchange = () => {
        if (!location.hash) {
            modal.hide();
        }
    };
};

export const initModal = () => {
    document.addEventListener('rtsoft-grid-js-action-event', (event) => {
        if (!event.rowParameters || !event.rowParameters.link || !event.rowParameters.params) {
            return;
        }

        const url = constructUrl(event.rowParameters.link, event.rowParameters.params);

        const options = {
            spinnerSelector: '#' + event.gridId,
            history: 'off',
        };

        window.naja.makeRequest('GET', url, null, options);
    });

    window.naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        if (event && event.detail.snippet && event.detail.snippet.dataset) {
            const snippetDataset = event.detail.snippet.dataset;
            // Otevreni nebo zavreni modalu pri prekresleni urciteho snippetu
            if (
                (snippetDataset.openModalSnippet === 'true' || snippetDataset.closeModalSnippet === 'true') &&
                snippetDataset.modalId
            ) {
                const container = document.getElementById(snippetDataset.modalId);

                // Pokud neni element s pozadovanym id ukoncime zpracovani
                if (!container) {
                    return;
                }

                // Vytvarime instanci modalu
                const modal = bootstrapModal.getOrCreateInstance(container);

                // Po zobrazeni modalu se odpali resize -je to kvuli karuselu,
                // ktery se jinak inicializuje s nulovou sirkou
                container.addEventListener('shown.bs.modal', () => {
                    window.dispatchEvent(new Event('resize'));
                });

                if (snippetDataset.openModalSnippet === 'true') {
                    modal.show();

                    // Pokud chceme modal zavirat tlacitkem zpet
                    if (snippetDataset.modalCloseOnHistoryBack === 'true') {
                        setupModalForCloseByBackButton(modal);
                    }
                }

                if (snippetDataset.closeModalSnippet === 'true') {
                    modal.hide();

                    // Prekresleni gridu, pokud mame jeho ID
                    if (snippetDataset.refreshGridId) {
                        // Vynucení přenačtení dat přímo na html elementu gridu
                        refreshGrid(snippetDataset.refreshGridId);
                    }
                }
            }
        }
    });
};
