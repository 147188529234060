import { getJsData } from '../../../shared/js/utils/js-data';
import {
    createGtmAddToCartEvent,
    createGtmRemoveFromCartEvent,
    createGtmProductClickEvent,
    createGtmCheckoutOptionEvent,
} from '../../libs/gtm/gtm';

export const getGtmConfigData = () => getJsData().gtm;

export const fireGtmEvent = (gtmEvent) => {
    window.dataLayer.push(gtmEvent);
};

const initCheckoutOptionGtmEvent = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.gtm-checkout-option').forEach((element) => {
        element.addEventListener('change', () => {
            if (
                typeof element.dataset.gtmCheckoutStep === 'undefined' ||
                typeof element.dataset.gtmCheckoutValue === 'undefined'
            ) {
                return;
            }

            const step = Number.parseInt(element.dataset.gtmCheckoutStep);

            if (Number.isNaN(step)) {
                return;
            }

            fireGtmEvent(
                createGtmCheckoutOptionEvent(
                    step,
                    element.dataset.gtmCheckoutValue,
                    getGtmConfigData().cartProductsData
                )
            );
        });
    });
};

const initAddToCartGtmEvent = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.gtm-add-to-cart').forEach((element) => {
        bindGtmCartManipulationEvent(element, 'add');
    });
};

const initRemoveFromCartGtmEvent = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.gtm-remove-from-cart').forEach((element) => {
        bindGtmCartManipulationEvent(element, 'remove');
    });

    document.querySelectorAll(scopePrefix + '.gtm-cart-remove').forEach((element) => {
        element.addEventListener('click', (event) => {
            const rowElement = event.target.parentElement.parentElement;
            const buttonRemove = rowElement.querySelector('.gtm-remove-from-cart');

            if (buttonRemove) {
                fireGtmChangeCart(buttonRemove, 'remove', true);
            }
        });
    });
};

const initProductClickGtmEvent = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.gtm-product-click').forEach((element) => {
        element.addEventListener('click', () => {
            // Nacteme data z atributu
            const productData = getProductData(element);

            if (productData === null) {
                return;
            }

            const list = productData.list || null;

            delete productData.list;

            fireGtmEvent(createGtmProductClickEvent([productData], list));
        });
    });
};

const initSendDataGtmEvent = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.gtm-form-event').forEach((element) => {
        element.addEventListener('click', () => {
            // Vezmeme data z atributu eventData
            const eventData = decodeURI(element.dataset.formEvent);

            if (eventData === null) {
                return;
            }

            fireGtmEvent(JSON.parse(eventData));
        });
    });
};

const bindGtmCartManipulationEvent = (element, action) => {
    const gtmEventsCreators = {
        add: createGtmAddToCartEvent,
        remove: createGtmRemoveFromCartEvent,
    };

    if (!gtmEventsCreators[action]) {
        return;
    }

    element.addEventListener('click', (element) => {
        fireGtmChangeCart(element, action, false);
    });
};

function fireGtmChangeCart(element, action, removeAll) {
    const targetEl = element instanceof PointerEvent ? element.currentTarget : element;
    const elDataset = targetEl.dataset;

    if (!elDataset || elDataset.gtmProductData === undefined) {
        return;
    }

    // Nacteme data produktu z atributu - když nemáme, není třeba pokračovat
    const productData = JSON.parse(elDataset.gtmProductData);

    if (productData === null) {
        return;
    }

    // Defaultni hodnota navyseni je 1
    let quantity = 1;

    // Mame zadane prime mnozstvi kolik zmenit a zároveň nejde o odebrání celého produktu z košíku
    if (elDataset.gtmCartQuantity && !removeAll) {
        quantity = Number.parseFloat(elDataset.gtmCartQuantity);
    } else {
        // Najdeme hodnotu patricneho inputu mnozstvi
        let quantityInput = null;
        // Pokud máme předané id inputu (např. přidání do košíku z detailu produktu), tak použijeme to
        // Jinak zjistíme hodnotu z inputu (např. editace množství z košíku)
        if (elDataset.gtmCartQuantityId) {
            quantityInput = document.getElementById(elDataset.gtmCartQuantityId);
        } else {
            const rowElement = targetEl.parentElement.parentElement;
            if (rowElement !== null) {
                quantityInput = rowElement.querySelector('.incrementable__input');
            }
        }

        if (quantityInput !== null) {
            quantity = Number.parseFloat(quantityInput.value);
        }
    }

    // Pozor, množství musíme mít, jinak nepokračujeme
    if (Number.isNaN(quantity)) {
        return;
    }

    // Pridame mnozstvi
    productData.value.amount = quantity;

    const eventData = {
        event: action === 'remove' ? 'removeFromCart' : 'addToCart',
        product: productData,
    };

    fireGtmEvent(eventData);
}

const getProductFromProducts = (productCode) => {
    const products = getJsData().products.filter((product) => product.id === productCode);

    return products.length > 0 ? products[0] : null;
};

const getProductData = (element) => {
    const { code, list, position } = JSON.parse(element.dataset.gtmProductData);

    const product = getProductFromProducts(code);
    if (!product) {
        return null;
    }

    return { ...product, list, position };
};

export const initGtmEvents = (scope) => {
    initAddToCartGtmEvent(scope);
    initRemoveFromCartGtmEvent(scope);
    initProductClickGtmEvent(scope);
    initCheckoutOptionGtmEvent(scope);
    initSendDataGtmEvent(scope);
};

export const initGtmEventsIfGtmActive = (scope) => {
    if (getGtmConfigData().enabled) {
        initGtmEvents(scope);
    }
};
