import Glide from '@glidejs/glide';

export const initCardCarousels = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.card-carousel').forEach((element) => {
        const slidesPhysicalCount = element.querySelectorAll('.card-carousel__item').length;

        const perViewHandled = (items) => Math.min(items, slidesPhysicalCount);

        const resolveNumberOfSlides = (cardCarousel) => {
            if (cardCarousel.settings.perView < slidesPhysicalCount) {
                cardCarousel.enable();
                element.querySelector('.card-carousel__arrows').classList.remove('d-none');
            } else {
                cardCarousel.disable();
                element.querySelector('.card-carousel__arrows').classList.add('d-none');
            }
        };

        const options = {
            autoplay: false,
            perView: perViewHandled(4),
            breakpoints: {
                991: {
                    perView: perViewHandled(3),
                },
                719: {
                    perView: perViewHandled(2),
                },
                479: {
                    perView: perViewHandled(1),
                },
            },
            type: 'carousel',
            gap: 0,
        };

        if (element.classList.contains('card-carousel--max-3-items')) {
            options['perView'] = perViewHandled(3);
            options['breakpoints'][991]['perView'] = perViewHandled(2);
            options['breakpoints'][479]['perView'] = perViewHandled(1);
        }

        if (element.dataset.glideType === 'slider') {
            options['type'] = 'slider';
            options['rewind'] = false;
            options['bound'] = true;
        }

        const cardCarousel = new Glide(element, options);

        cardCarousel.on(['resize', 'mount.after'], () => {
            resolveNumberOfSlides(cardCarousel);
        });

        cardCarousel.mount();
    });
};
