window['recaptchaListenerInitialized'] =
    window['recaptchaListenerInitialized'] === undefined ? false : window['recaptchaListenerInitialized'];

export const initReCaptcha = (selector = null) => {
    // map of <htmlID>: <widget/client ID>
    const clientIDs = {};

    // Register reCAPTCHAs
    window['g_onRecaptchaLoad'] = function () {
        document.querySelectorAll('.g-recaptcha').forEach((element) => {
            try {
                clientIDs[element.id] = grecaptcha.render(
                    element,
                    {
                        size: 'invisible',
                        badge: 'bottomright',
                    },
                    true
                );
            } catch (error) {
                /* pokud již byla na elementu vykreslená */
            }
        });
    };

    const formSelector = selector ? selector : 'form';

    // Register form submit event listeners
    document.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll(formSelector).forEach((element) => {
            // Register callback only if form contains recaptcha
            if (element.querySelector('.g-recaptcha')) {
                element.addEventListener('submit', formSubmitEventCallback);
            }
        });

        // pro ajaxové formuláře
        if (!window['recaptchaListenerInitialized']) {
            naja.addEventListener('before', (event) => {
                const formData = event.detail.data;

                if (
                    formData !== null &&
                    formData instanceof FormData &&
                    formData.has('g-recaptcha-response') &&
                    formData.get('g-recaptcha-response') === ''
                ) {
                    event.preventDefault();
                    // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then, promise/always-return
                    grecaptcha.execute().then((token) => {
                        formData.set('g-recaptcha-response', token);
                        naja.makeRequest(event.detail.method, event.detail.url, formData, event.detail.options);
                    });
                }
            });

            window['recaptchaListenerInitialized'] = true;
        }
    });
};

const formSubmitEventCallback = (event) => {
    const form = event.currentTarget;
    if (Nette.validateForm(form, true)) {
        const submitBtn = form['nette-submittedBy'];
        event.preventDefault();

        // execute only reCAPTCHAs in submitted form
        // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
        grecaptcha.execute().then((token) => {
            // Put token into hidden field
            document.querySelectorAll('.g-recaptcha-response').forEach((element) => {
                element.textContent = token;
            });

            // Remove submit event listener, otherwise it will call itself infinitely
            form.removeEventListener('submit', formSubmitEventCallback);

            // Submit the form again
            // eslint-disable-next-line promise/always-return
            if (submitBtn) {
                submitBtn.click();
            } else {
                const event = document.createEvent('HTMLEvents');
                event.initEvent('submit', true, false);
                form.dispatchEvent(event);
            }
        });
    }
};
