import '../../../shared/js/utils/polyfills';

export function initDropdowns(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.dropdown__trigger').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();

            const trigger = event.currentTarget;
            const target = trigger.closest('.dropdown');

            if (target === null) {
                return;
            }

            const open = target.classList.contains('dropdown--open');

            if (open) {
                target.classList.remove('dropdown--open');

                return;
            }

            closeDropdowns(target);

            target.classList.add('dropdown--open');

            bindDropdownCloser();
        });
    });

    // Navazani fixace otevreneho dropdownu po kliku dovnitr neho
    document.querySelectorAll(scopePrefix + '.dropdown__menu').forEach((element) => {
        element.addEventListener('click', (event) => {
            const trigger = event.currentTarget;
            const target = trigger.closest('.dropdown');

            if (target === null || target.classList.contains('dropdown--open')) {
                return;
            }

            closeDropdowns();

            target.classList.add('dropdown--open');

            bindDropdownCloser();
        });
    });
}

function bindDropdownCloser() {
    document.removeEventListener('click', dropdownCloser);
    document.addEventListener('click', dropdownCloser);
}

function dropdownCloser(event) {
    closeDropdowns(event.target);
}

function closeDropdowns(target) {
    const parentDropdowns = [];

    if (typeof target !== 'undefined') {
        let parentNode = target.parentNode;

        // Hledame nadrazene dropdowny
        while (parentNode) {
            if (typeof parentNode.classList !== 'undefined' && parentNode.classList.contains('dropdown')) {
                parentDropdowns.unshift(parentNode);
            }

            parentNode = parentNode.parentNode;
        }
    }

    let areExcluded = false;

    document.querySelectorAll('.dropdown--open').forEach((element) => {
        // Nezavirame rodicovske dropdowny
        for (let i = 0; i < parentDropdowns.length; i++) {
            if (element.isSameNode(parentDropdowns[i])) {
                areExcluded = true;
                return;
            }
        }

        element.classList.remove('dropdown--open');
    });

    // Pokud byly ponechany nejake dropdowny otevrene nechavame listener
    if (areExcluded) {
        return;
    }

    document.removeEventListener('click', dropdownCloser);
}
