export function initSubmenu() {
    const tabs = document.querySelectorAll('.menu-main__item--has-submenu.menu-main__item.menu-main__item--level-1');
    const sumbenu = document.querySelector('.menu-main__container--absolute');
    let isHoverSubmenu = false;
    let isHoverTab = false;

    if (!sumbenu) {
        return false;
    }

    sumbenu.addEventListener('mouseover', () => {
        isHoverSubmenu = true;
    });

    sumbenu.addEventListener('mouseleave', () => {
        isHoverSubmenu = false;
        setTimeout(() => {
            if (!isHoverTab) {
                removeAllChildNodes(sumbenu);
            }
        }, 10);
    });

    tabs.forEach((tab) => {
        tab.addEventListener('mouseover', () => {
            isHoverTab = true;
            const t = setTimeout(() => {
                // Kontrola, proti nechtemu otevreni submenu (uzivatel pouze prejizdel mysi pres menu nize na stranku)
                if (!tab.matches(':hover')) {
                    clearTimeout(t);

                    return false;
                }

                const content = tab.querySelector(':scope > .menu-main__container.menu-main__container--level-2');
                removeAllChildNodes(sumbenu);
                sumbenu.appendChild(content.cloneNode(true));
                sumbenu.querySelector(':scope >.menu-main__container.menu-main__container--level-2').style.display =
                    'block';
            }, 300);
        });

        tab.addEventListener('mouseleave', () => {
            isHoverTab = false;
            setTimeout(() => {
                if (isHoverTab) {
                    return;
                }

                if (!isHoverSubmenu) {
                    removeAllChildNodes(sumbenu);
                }
            }, 10);
        });
    });
}

function removeAllChildNodes(parent) {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
}
