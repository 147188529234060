import '../../../shared/js/utils/polyfills';
import { createDebouncedFunc } from '../../../shared/js/utils/global-helpers';

export function initStickyMenu() {
    const nav = document.getElementById('page-header');
    const navHeight = nav.offsetHeight;

    document.getElementById('page-header').style['height'] = navHeight + 'px';

    stickyNav();

    function stickyNav() {
        const navHeightSmall = nav.offsetHeight;

        if (window.scrollY > navHeight + 50) {
            nav.classList.add('sticky');
        } else if (window.scrollY < navHeightSmall + 50) {
            nav.classList.remove('sticky');
        }
    }

    window.addEventListener('scroll', stickyNav);
    window.addEventListener(
        'resize',
        createDebouncedFunc(() => {
            document.getElementById('page-header').style['height'] = null;
            nav.classList.remove('sticky');
            document.getElementById('page-header').style['height'] = nav.offsetHeight + 'px';

            stickyNav();
        }, 50)
    );
}
