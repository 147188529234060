import '../../../shared/js/utils/polyfills';

export function initSwitchable(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.switchable__trigger').forEach((element) => {
        element.addEventListener('click', (event) => {
            const trigger = event.currentTarget;

            if (!('noPreventDefault' in trigger.dataset)) {
                event.preventDefault();
            }

            const target = trigger.closest('.switchable');

            if (target === null) {
                return;
            }

            if (target.classList.contains('switchable--off')) {
                target.classList.remove('switchable--off');
            } else {
                target.classList.add('switchable--off');
            }
        });
    });
}
