export const initSearchForm = () => {
    const searchInput = document.querySelector('.search-form__input-field');

    if (searchInput === null) {
        return;
    }

    resolveSubmitButton(searchInput);

    document.querySelector('.search-form__input-field').addEventListener('input', (event) => {
        resolveSubmitButton(event.target);
    });
};

const resolveSubmitButton = (searchInput) => {
    const container = searchInput.closest('.search-form');

    if (container === null) {
        return;
    }

    if (searchInput.value.length > 2) {
        container.classList.add('search-form--query-filled');
    } else {
        container.classList.remove('search-form--query-filled');
    }
};
