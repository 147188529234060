class IncrementableInput {
    /**
     * @param {HTMLInputElement} input
     */
    constructor(input) {
        this.input = input;
        this.buttons = input.closest('.incrementable').querySelectorAll('.incrementable__control');

        this.step = 'incrementableStep' in input.dataset ? parseInt(input.dataset.incrementableStep) : 1;

        this.minValue = 'incrementableMin' in this.input.dataset ? parseInt(this.input.dataset.incrementableMin) : null;

        this.maxValue = 'incrementableMax' in this.input.dataset ? parseInt(this.input.dataset.incrementableMax) : null;

        this.initInput();
        this.initButtons();
    }

    initInput() {
        // Uchovame si platnou hodnotu
        this.validValue = this.input.value;

        this.input.addEventListener('change', () => this.changeValueHandler(event));

        this.input.addEventListener('keydown', (event) => {
            // Escape vrati puvodni hodnotu
            if (event.keyCode === 27) {
                this.input.value = this.validValue;
            }

            // Enter a tab zrusi focus
            if (event.keyCode === 9 || event.keyCode === 13) {
                this.input.blur();
            }
        });
    }

    initButtons() {
        this.buttons.forEach((element) => {
            element.dataset.increment = element.classList.contains('incrementable__control--dec')
                ? -this.step
                : this.step;

            this.setButtonState(element);

            element.addEventListener('click', (event) => {
                this.handleOnIncrementableButtonClick(element, event);
            });
        });
    }

    resolveNextValue(button, callBack) {
        // Zakladni navyseni
        const incrementAmount = parseFloat(button.dataset.increment);
        const inputValue = parseFloat(this.input.value);

        if (isNaN(inputValue)) {
            throw new Error('Value in input is not a number!');
        }

        let nextValue = inputValue + incrementAmount;

        if (incrementAmount > 0) {
            if (this.maxValue !== null && nextValue > this.maxValue) {
                nextValue = null;
            }
        } else {
            if (this.minValue !== null && nextValue < this.minValue) {
                nextValue = null;
            }
        }

        callBack(nextValue, button);
    }

    setButtonState(button) {
        this.resolveNextValue(button, (nextValue, button) => {
            if (nextValue !== null) {
                button.classList.remove('disabled');
                button.removeAttribute('data-naja-disabled');

                if (button.hasAttribute('data-href-stash')) {
                    button.setAttribute('href', button.getAttribute('data-href-stash'));
                    button.removeAttribute('data-href-stash');
                }
            } else {
                button.classList.add('disabled');
                button.setAttribute('data-naja-disabled', null);

                if (button.hasAttribute('href')) {
                    button.setAttribute('data-href-stash', button.getAttribute('href'));
                    button.removeAttribute('href');
                }
            }
        });
    }

    handleOnIncrementableButtonClick(button, event) {
        // Tlacitko neni aktivni
        if (button.classList.contains('disabled')) {
            event.preventDefault();
            return;
        }

        try {
            this.resolveNextValue(button, (nextValue) => {
                // Pokud neni mozna dalsi hodnota koncime
                if (nextValue === null) {
                    return;
                }

                const changeEvent = new CustomEvent('incrementableChange', {
                    detail: {
                        oldValue: parseFloat(this.input.value),
                        newValue: parseFloat(nextValue),
                    },
                });

                // Nastavime hodnotu inputu
                this.input.value = nextValue;
                this.input.dispatchEvent(changeEvent);

                this.buttons.forEach((element) => {
                    this.setButtonState(element);
                });
            });
        } catch (err) {
            console.error(err);
        }
    }

    changeValueHandler(event) {
        event.preventDefault();

        // Pokud je nova hodnota validni
        if (this.validateValue()) {
            // Nastavi se jako validni

            const changeEvent = new CustomEvent('incrementableChange', {
                detail: {
                    oldValue: parseFloat(this.validValue),
                    newValue: parseFloat(this.input.value),
                },
            });

            this.validValue = this.input.value;

            this.input.dispatchEvent(changeEvent);

            return;
        }

        // Vratime posledni validni hodnotu
        this.input.value = this.validValue;
    }

    validateValue() {
        const value = parseFloat(this.input.value);

        if (isNaN(value)) {
            return false;
        }

        if (this.maxValue !== null && value > this.maxValue) {
            return false;
        }

        if (this.minValue !== null && value < this.minValue) {
            return false;
        }

        return true;
    }
}

export function initIncrementableInputs(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : `${scope} `;

    document.querySelectorAll(`${scopePrefix}.incrementable__input`).forEach((input) => new IncrementableInput(input));
}
