import '../../../shared/js/utils/polyfills';
import { getJsData } from '../../../shared/js/utils/js-data';
import * as bootstrapModal from 'bootstrap/js/dist/modal';

export function initConfirm(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '[data-confirm]').forEach((element) => {
        element.addEventListener('click', (event) => {
            resolveConfirmationClick(event);
        });
    });
}

const resolveConfirmationClick = (event) => {
    const target = event.currentTarget;

    // Prvek je uz potvrzen
    if (target.hasAttribute('data-confirmed')) {
        return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    createConfirmDialog(target.dataset.confirmPrompt, () => {
        target.setAttribute('data-confirmed', '');

        if (event.type === 'click') {
            target.click();
        } else {
            target.dispatchEvent(new CustomEvent(event.type));
        }

        target.removeAttribute('data-confirmed');
    });
};

export const createConfirmDialog = (confirmPrompt, callback) => {
    if (typeof confirmPrompt === 'undefined') {
        confirmPrompt = 'Jste si jist?';
    }

    const dialog = document.createElement('div');
    const translations = getJsData().translations.confirm;

    dialog.setAttribute('class', 'modal fade modal');
    dialog.setAttribute('tabindex', '-1');
    dialog.setAttribute('data-coreui-backdrop', 'static');
    dialog.setAttribute('aria-hidden', 'true');
    dialog.innerHTML =
        '<div class="modal-dialog modal-dialog--confirm-modal">' +
        '<div class="modal-content w-auto">' +
        '<div class="general-confirm-popup">' +
        '<div class="general-confirm-popup__prompt">' +
        confirmPrompt +
        '</div>' +
        '<div class="general-confirm-popup__controls">' +
        '<button class="btn btn--sm btn--default general-confirm-popup__close-button">' +
        translations.no +
        '</button>' +
        '<button class="btn btn--sm btn--primary general-confirm-popup__confirm-button">' +
        translations.yes +
        '</button>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>';

    dialog.querySelector('.general-confirm-popup__close-button').addEventListener('click', () => {
        // Zavirame modal
        const modal = bootstrapModal.getOrCreateInstance(dialog);
        modal.hide();
    });

    dialog.querySelector('.general-confirm-popup__confirm-button').addEventListener('click', () => {
        callback();

        // Zavirame modal
        const modal = bootstrapModal.getOrCreateInstance(dialog);
        modal.hide();
    });

    dialog.addEventListener('hidden.bs.modal', () => {
        dialog.remove();
    });

    document.body.append(dialog);

    // Vytvarime a zobrazujeme instanci modalu
    const modal = bootstrapModal.getOrCreateInstance(dialog);
    modal.show();

    return dialog;
};
