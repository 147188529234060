import { logException } from './error-reporting';

const displayConnectionErrorAlert = () => {
    document.getElementById('connection-error-alert-container').style.display = 'block';
};

const hideConnectionErrorAlert = () => {
    document.getElementById('connection-error-alert-container').style.display = 'none';
};

const displayGeneralErrorAlert = () => {
    document.getElementById('general-error-alert-container').style.display = 'block';
};

const hideGeneralErrorAlert = () => {
    document.getElementById('general-error-alert-container').style.display = 'none';
};

const isConnectionError = (error) => error.message === 'Connection aborted';

export const displayConnectionErrorHandler = {
    displayError: (error) => {
        if (isConnectionError(error)) {
            displayConnectionErrorAlert();
        } else {
            logException(error);
            displayGeneralErrorAlert();
        }
    },
    hideError: () => {
        hideConnectionErrorAlert();
        hideGeneralErrorAlert();
    },
};
