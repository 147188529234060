import { createConfirmDialog } from './confirm';

export const initCartItemQuantityModifiers = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document
        .querySelectorAll(scopePrefix + '.incrementable__input[data-cart-quantity-modifier-item-id]')
        .forEach((element) => {
            const container = element.closest('.incrementable');
            const buttonDec = container.querySelector('.incrementable__control--dec');

            element.addEventListener('incrementableChange', (event) => {
                const input = event.target;
                const cartItemId = parseInt(input.dataset.cartQuantityModifierItemId);

                if (isNaN(cartItemId)) {
                    return;
                }

                const newQuantity = event.detail.newValue;

                if (isNaN(newQuantity)) {
                    return;
                }

                if (newQuantity === 0) {
                    input.value = event.detail.oldValue;

                    createConfirmDialog(buttonDec.dataset.confirmPrompt, () => {
                        naja.makeRequest(
                            'GET',
                            '',
                            {
                                do: 'modifyInCart',
                                storageCartItemId: cartItemId,
                                quantity: newQuantity,
                            },
                            {
                                history: 'off',
                                spinnerSelector: input.dataset.najaSpinnerSelector,
                            }
                        );
                    });

                    return;
                }

                naja.makeRequest(
                    'GET',
                    '',
                    {
                        do: 'modifyInCart',
                        storageCartItemId: cartItemId,
                        quantity: newQuantity,
                    },
                    {
                        history: 'off',
                        spinnerSelector: input.dataset.najaSpinnerSelector,
                    }
                );
            });
        });
};
