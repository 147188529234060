import '../../../shared/js/utils/polyfills';

export function initSubmitOnChange(scope, onBeforeSubmit = null) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.submit-on-change__trigger').forEach((element) => {
        // Pokud je elementem Select2, tak se registruje jQuery change event
        if (element.parentElement.classList.contains('select2-wrapper')) {
            $(element).on('change', (event) => {
                submitAfterChange(event.target, onBeforeSubmit);
            });
        } else {
            element.addEventListener('change', (event) => {
                submitAfterChange(event.target, onBeforeSubmit);
            });
        }
    });
}

export function submitAfterChange(element, onBeforeSubmit = null) {
    let form = element.closest('.submit-on-change');

    if (form === null) {
        form = element.closest('form');
    }

    form.classList.add('submit-on-change__is-triggered');

    if (onBeforeSubmit !== null) {
        onBeforeSubmit(form);
    }

    const submitButton = form.querySelector('.submit-on-change__target');

    // Mame urcene odesilaci tlacitko -klikneme na nej
    if (submitButton !== null) {
        submitButton.click();

        return;
    }

    // Mame urceno, ze form ma byt jako ajax
    if (form.classList.contains('submit-on-change--ajax')) {
        naja.uiHandler.submitForm(form);

        return;
    }

    form.submit();
}
