import '../../shared/js/utils/polyfills';
import { initSubmitOnChange } from '../../shared/libs/form/submit-on-change';
import { initMenu } from './layout/menu';
// import { initSearchMenu } from './searchMenu';
import { initSwitchable } from './components/switchable';
import { initDismissable } from './components/dismissable';
import { initDropdowns } from './components/dropdown';
import { initCollapse } from './components/collapse';
// import { initWhisperer } from './react/whisperer/whisperer';
import { initIncrementableInputs } from './components/incrementable-input';
import naja from 'naja';
import { LoaderExtension } from '../../shared/libs/naja/naja-spinners';
import { LiveForm, Nette } from 'live-form-validation';
import { uiBlocker } from '../../shared/libs/ui-blocker/ui-blocker';
import { initReCaptcha } from './form/recaptcha';
import { initSentryFromWebConfig } from '../../shared/js/utils/sentry';
import { displayConnectionErrorHandler } from '../../shared/js/utils/xhr-display-connection-error-handler';
import { initSliders } from './components/slider';
import { initGtmEventsIfGtmActive } from './gtm/gtm';
import { initInfoBar } from './components/info-bar';
// import { getJsData } from '../../shared/js/utils/js-data';
import { initFooterMenu } from './components/footer-menu';
import { initSelectbox } from './components/custom-selectbox';
import 'toastr/toastr';
import { initModal } from './components/modal';
import { initSpinnerSelector } from '../../shared/libs/ui-blocker/spinner-selector';
import { handleLastClickedElement } from './sections/product-filter/last-clicked-checkbox';
import { initGalleries } from './components/lightboxGallery';
import { initConfirm } from './components/confirm';
import { initDisplayablePassword } from './components/displayable-password';
import { initScrollTo, scrollTo } from './components/scroll-to';
import { initStickyMenu } from './components/sticky-menu';
import { initCardCarousels } from './components/card-carousel';
import { initTabletMenu } from './components/tablet-menu';
import { initSubmenu } from './components/submenu';
import { initNumberFormatInput } from './components/number-format-input';
import { initSearchForm } from './components/search-form';
import { initCartItemQuantityModifiers } from './components/cart-item-quantity-modifier';
import { initOpenOnClickTooltips } from './components/tooltip';
import { initEcomailCartTrack } from './ecomail/ecomail';
import { initTableScroll } from './components/table-scroll';

initSentryFromWebConfig();

window.LiveForm = LiveForm;
window.Nette = Nette;
window.naja = naja;
Nette.initOnLoad();

// Globalni uloziste pro instance Choices JS
window.choicesInstances = [];

// Globalni uloziste pro instance Range Slideru
window.bootstrapSliders = [];

initReCaptcha();

LiveForm.setOptions({
    messageErrorClass: 'form-input-message form-input-message--danger',
    messageErrorPrefix: '',
    showValid: true,
});

initModal();

// Toggle page fade if external element is shown
/*
const toggleContentFade = (shown) => {
    if (shown) {
        document.body.classList.add('search-form-open');
    } else {
        document.body.classList.remove('search-form-open');
    }
};
 */

document.addEventListener('DOMContentLoaded', () => {
    initStickyMenu();

    initConfirm();

    initSelectbox();

    // inicializueme menu
    initMenu();
    // initSearchMenu(); // @TODO asi lepsi reseni, ale nutno poladit pripadne se zbytkem menu.js

    initSearchForm();

    // inicializueme dropdowns
    initDropdowns();

    // inicializueme prepinatelne
    initSwitchable();

    // inicializueme prepinatelne
    initDismissable();

    // inicializujeme GTM udalosti
    initGtmEventsIfGtmActive();

    // inicializujeme collapse toggler
    initCollapse();

    // inicializace sbalitelnych footer menu
    initFooterMenu();

    initScrollTo();

    initSubmenu();

    initCardCarousels();

    initTableScroll();

    // inicializueme našeptávač - vypnuto, používá se Luigi's box
    // const whisperer = document.getElementById('whisperer-head-wrapper');

    // const jsData = getJsData();

    /* Vypnuto, používá se Luigi's box
    if (whisperer) {
        initWhisperer(
            whisperer, // id formjuláře, který našeptávač spouští
            document.getElementById('whisperer-head-wrapper-result'), // container pro výsledky,
            350, // debounce
            3, // min chars
            whisperer.dataset.whisperQueryUrl,
            whisperer.dataset.whisperDetailUrl,
            toggleContentFade,
            jsData.whisperer.suggestedItems
        );
    }
    */

    initOpenOnClickTooltips();

    const uiBlockerInitialized = uiBlocker();

    initDisplayablePassword();

    initTabletMenu();

    // naja.initialize.bind(naja);
    naja.registerExtension(new LoaderExtension('#loader', uiBlockerInitialized, displayConnectionErrorHandler));

    if (naja.initialized === false) {
        naja.initialize();
    }

    // Pro Konfirmacni dialogy AJAX odkazu
    naja.uiHandler.addEventListener('interaction', (event) => {
        const { element } = event.detail;

        // Pokud potrebuje prvek potvrzeni a neni potvrzen, zrusime volani
        if (element.hasAttribute('data-confirm') && !element.hasAttribute('data-confirmed')) {
            event.preventDefault();
        }

        // Attribut pro zneaktivneni naja dotazu
        if (element.hasAttribute('data-naja-disabled')) {
            event.preventDefault();
        }
    });

    initSpinnerSelector(uiBlockerInitialized);

    naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        const snippetId = `#${event.detail.snippet.id}`;

        initConfirm(snippetId);
        initSelectbox(snippetId);
        initSwitchable(snippetId);
        initDropdowns(snippetId);
        initDismissable(snippetId);
        initSubmitOnChange(snippetId);
        initGtmEventsIfGtmActive(snippetId);
        initIncrementableInputs(snippetId);
        initSpinnerSelector(uiBlockerInitialized, snippetId);
        initSelectbox(snippetId);
        initNumberFormatInput(snippetId);
        initCardCarousels(snippetId);
        initDisplayablePassword(snippetId);
        initScrollTo(snippetId);
        initSliders(snippetId + ' .bs-slider');
        initCartItemQuantityModifiers(snippetId);
        initOpenOnClickTooltips(snippetId);
        initTableScroll(snippetId);
    });

    naja.addEventListener('success', (event) => {
        // Pokud s ajaxem prislo ID na ktere nascrollovat
        if (event.detail.payload.ajaxScrollToHtmlId !== null) {
            const element = document.getElementById(event.detail.payload.ajaxScrollToHtmlId);

            if (element !== null) {
                scrollTo(element);
            }
        }
    });

    // inicializace YT video elementů

    // hydrateYoutubeElements();

    // inicializace inkrementovanych inputu pro pridani do kosiku
    initIncrementableInputs();

    initNumberFormatInput();

    initCartItemQuantityModifiers();

    // ui blocker - ukázka použití

    // setTimeout(() => {
    //     const elements = document.body.querySelectorAll('.bgimg-product-box__content-wrapper > .btn--primary')
    //     for(let i = 0; i < elements.length; i++){
    //         uiBlocker.block(elements[i])
    //     }
    //     const blockedInstance = uiBlocker.block('.bgimg-article-box__image')
    //     setTimeout(() => {
    //         blockedInstance.unblock()
    //         for(let i = 0; i < elements.length; i++){
    //             uiBlocker.unblock(elements[i])
    //         }
    //     }, 5000)
    // }, 3000)

    // zavěsíme na všechny slidery
    initSliders('.bs-slider');

    initSubmitOnChange(undefined, handleLastClickedElement);

    initInfoBar();

    initGalleries();

    initEcomailCartTrack();

    // initRtsoftCookieConsent(jsData.cookieconsent.language, jsData.cookieconsent.data);
});

window.isTouchEnabled = () =>
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
