import '../../../shared/js/utils/polyfills';
import { getJsData } from '../../../shared/js/utils/js-data';

export function initSelectbox(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    const translations = getJsData().translations.choicesJS;

    document.querySelectorAll(scopePrefix + '.js-choice').forEach((element) => {
        const options = {
            searchEnabled: element.hasAttribute('data-choice-js-search'),
            searchFields: ['label'],
            itemSelectText: '',
            allowHTML: true,
            position: 'bellow', // top koliduje s hlavickou a z-indexovanymi elementy
            noResultsText: translations.noResultsText,
            noChoicesText: translations.noChoicesText,
            shouldSort: false,
        };

        // Vytvorime instanci Choices JS a index ulozime do datasetu selectu
        // eslint-disable-next-line
        element.dataset.choicesInstanceIndex = window.choicesInstances.push(new Choices(element, options)) - 1;
    });
}
