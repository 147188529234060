import Xhr from 'xhr';
import { debounce } from 'throttle-debounce';

export function constructUrl(uri, queryParams) {
    uri += uri.indexOf('?') !== -1 ? '&' : '?';
    // Pridani GET parametru
    Object.keys(queryParams).forEach((key) => {
        uri += `${key}=${encodeURI(queryParams[key])}&`;
    });

    // Odstraneni posledniho &
    if (uri.lastIndexOf('&') === uri.length - 1) {
        uri = uri.substring(0, uri.length - 1);
    }

    return uri;
}

export const makeXhrRequest = (method, uri, body, queryParams, callback) => {
    if (method.toLowerCase() === 'get' && queryParams) {
        uri = constructUrl(uri, queryParams);
    }

    Xhr(
        {
            method,
            body: method.toLowerCase() === 'post' && body ? JSON.stringify(body) : undefined,
            uri,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
        },
        (err, resp, body) => {
            callback && !err ? callback({ err, resp, body }) : err ? console.error(err) : null;
        }
    );
};

export const createDebouncedFunc = (func, dbnc) => debounce(dbnc, false, func);
