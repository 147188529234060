import '../../../shared/js/utils/polyfills';

export function initMenu() {
    const headerTopMenu = document.getElementById('header-top-menu-switch');
    const widthSm = 480;

    if (!headerTopMenu) {
        return false;
    }

    document.querySelectorAll('#menu-main .menu-trigger').forEach((element) => {
        element.addEventListener('click', (event) => {
            // Pokud je displej vetsi, nez sm ignorujeme
            if (window.matchMedia('(min-width: ' + widthSm + 'px)').matches) {
                return;
            }

            event.preventDefault();

            const trigger = event.currentTarget;
            const target = trigger.closest('.menu-main__item--has-submenu');

            if (target === null) {
                return;
            }

            if (target.classList.contains('open')) {
                if (target.classList.contains('menu-main__item--level-0')) {
                    closeMenu();
                    return;
                }

                target.classList.remove('open', 'open-lowest');
                closeNestedSubmenu(target);

                const parentMenu = target.parentNode.closest('.menu-main__item--has-submenu.open');
                if (parentMenu === null) {
                    return;
                }

                parentMenu.classList.add('open-lowest');
            } else {
                closeNestedSubmenu(target.parentNode);
                target.classList.add('open', 'open-lowest');
                document.querySelector('#menu-main').classList.add('open');
                document.body.classList.add('menu-open');

                bindMenuCloser();

                const parentMenu = target.parentNode.closest('.menu-main__item--has-submenu.open-lowest');
                if (parentMenu === null) {
                    return;
                }

                parentMenu.classList.remove('open-lowest');

                const submenu = target.querySelector('.menu-level');
                const submenuRect = submenu ? submenu.getBoundingClientRect() : null;

                if (submenuRect && submenuRect.top < 0) {
                    const bodyRect = document.body.getBoundingClientRect();

                    window.scrollTo({
                        top: submenuRect.top - bodyRect.top,
                        behavior: 'smooth',
                    });
                }
            }
        });
    });

    // document.getElementById('menu-main-switch').addEventListener('click', (event) => {
    //     event.stopPropagation();
    //
    //     const trigger = event.currentTarget;
    //     const target = document.getElementById('menu-main');
    //
    //     if (target.classList.contains('open')) {
    //         closeMenu();
    //     } else {
    //         target.classList.add('open', 'open-lowest');
    //         trigger.classList.add('open');
    //
    //         bindMenuCloser();
    //         closeSearchForm();
    //
    //         document.body.classList.add('menu-open');
    //     }
    // });

    headerTopMenu.addEventListener('click', (event) => {
        event.stopPropagation();

        const trigger = event.currentTarget;
        const target = document.getElementById('header-top-menu');

        if (target.classList.contains('open')) {
            closeMenu();
        } else {
            target.classList.add('open');
            trigger.classList.add('open');

            bindTopMenuCloser();

            document.body.classList.add('menu-open');
        }
    });

    document.querySelectorAll('.search-menu-switch').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.stopPropagation();

            const trigger = event.currentTarget;
            const target = document.getElementById('search-menu');

            if (target.classList.contains('open')) {
                closeSearchForm();
            } else {
                target.classList.add('open');
                trigger.classList.add('open');

                bindSearchFormCloser();
                closeMenu();

                document.body.classList.add('menu-open');
            }
        });
    });
}

function closeNestedSubmenu(element) {
    element.querySelectorAll('.menu-main__item--has-submenu.open').forEach((element) => {
        element.classList.remove('open', 'open-lowest');
    });
}

function bindTopMenuCloser() {
    document.removeEventListener('click', topMenuCloser);
    document.addEventListener('click', topMenuCloser);
}

function topMenuCloser(event) {
    if (event.target.closest('#header-top-menu.open') !== null) {
        return;
    }

    closeTopMenu();
}

function closeTopMenu() {
    document.querySelectorAll('#header-top-menu, #header-top-menu-switch').forEach((element) => {
        element.classList.remove('open');
    });

    document.body.classList.remove('menu-open');

    document.removeEventListener('click', topMenuCloser);
}

function bindMenuCloser() {
    document.removeEventListener('click', menuCloser);
    document.addEventListener('click', menuCloser);
}

function menuCloser(event) {
    if (event.target.closest('#menu-main .menu-main__item--has-submenu.open, #menu-main.open') !== null) {
        return;
    }

    closeMenu();
}

function closeMenu() {
    document.querySelectorAll('#menu-main, #menu-main-switch').forEach((element) => {
        element.classList.remove('open', 'open-lowest');
    });

    closeNestedSubmenu(document.getElementById('menu-main'));

    document.body.classList.remove('menu-open');

    document.removeEventListener('click', menuCloser);
}

function bindSearchFormCloser() {
    document.removeEventListener('click', searchFormCloser);
    document.addEventListener('click', searchFormCloser);
}

function searchFormCloser(event) {
    if (event.target.closest('#search-menu') !== null) {
        return;
    }

    closeSearchForm();
}

function closeSearchForm() {
    document.querySelectorAll('#search-menu').forEach((element) => {
        element.classList.remove('open');
    });

    document.body.classList.remove('menu-open');

    document.removeEventListener('click', searchFormCloser);
}
