import '../../../shared/js/utils/polyfills';

export function initScrollTo(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.scrollTo').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();

            const trigger = event.currentTarget;

            const target = document.querySelector(trigger.getAttribute('href'));

            if (target === null) {
                return;
            }

            scrollTo(target);
        });
    });
}

export const scrollTo = (target) => {
    window.scrollTo({
        top:
            target.getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            // Odecteme vysku hlavicky -sticky
            document.getElementById('page-header').clientHeight,
        left: 0,
        behavior: 'smooth',
    });
};
