import { RTJS_lightbox } from '@rtsoft/rtjs';

export function initGalleries() {
    // Vsechny elementy s kodem galerie
    Array.from(document.querySelectorAll('[data-rt-lightbox-gallery]'))
        // Ziskani hodnot dataatributu pro galerii
        .map((element) => element.dataset.rtLightboxGallery)
        // Odstraneni duplicit
        .filter((value, index, array) => array.indexOf(value) === index)
        // Pro kazdy kod galerie inicializujeme
        .forEach((galleryName) => {
            RTJS_lightbox('[data-rt-lightbox-gallery="' + galleryName + '"]', {
                closeLabel: ' ',
                onOpened: () => {
                    const title = document.querySelector('.rt-lightbox__title');
                    const text = title.querySelector('span')
                        ? title.querySelector('span').innerText.replace('(', '').replace(')', '')
                        : null;
                    const thumbnails = document.querySelector('.rt-lightbox__thumbnails');

                    if (text) {
                        const newTitle = document.createElement('div');

                        newTitle.innerHTML = text;
                        title.appendChild(newTitle);
                        title.parentElement.style.display = 'initial';
                    } else {
                        title.parentElement.style.display = 'none';
                    }

                    if (thumbnails) {
                        document.getElementById('rt-lightbox-container').classList.add('rt-lightbox--with-thumbnails');
                    }
                },
                onItemChanged: () => {
                    const title = document.querySelector('.rt-lightbox__title');
                    const text = title.querySelector('span')
                        ? title.querySelector('span').innerText.replace('(', '').replace(')', '')
                        : null;

                    if (text) {
                        const newTitle = document.createElement('div');

                        newTitle.innerHTML = text;
                        title.appendChild(newTitle);
                        title.parentElement.style.display = 'initial';
                    } else {
                        title.parentElement.style.display = 'none';
                    }
                },
                onClosed: () => {
                    document.getElementById('rt-lightbox-container').classList.remove('rt-lightbox--with-thumbnails');
                },
            });
        });
}
