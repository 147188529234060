import ScrollBooster from 'scrollbooster';

export function initTabletMenu() {
    const leftButton = document.getElementById('tablet-menu-left-button');
    const rightButton = document.getElementById('tablet-menu-right-button');

    if (!leftButton && !rightButton) {
        return false;
    }

    const leftWrapper = leftButton.closest('.menu-main__button-wrapper');
    const rightWrapper = rightButton.closest('.menu-main__button-wrapper');

    const scrollBooster = new ScrollBooster({
        viewport: document.querySelector('.menu-main__container--level-0'),
        content: document.querySelector('.menu-main__list--level-0'),
        direction: 'horizontal',
        scrollMode: 'native',
        onUpdate: function (state) {
            setButtons(state, leftWrapper, rightWrapper, interval);
        },
    });

    // const scroll = document.querySelector('.menu-main__container--level-0');

    let interval;

    [leftButton, rightButton].forEach((button) => {
        ['mousedown', 'touchstart'].forEach((event) => {
            button.addEventListener(event, () => {
                const distance = button === rightButton ? 8 : -8;

                interval = setInterval(() => {
                    scrollBooster.setPosition({
                        x: scrollBooster.getState().position.x + distance,
                        y: 0,
                    });

                    setButtons(scrollBooster.getState(), leftWrapper, rightWrapper, interval);
                }, 30);
            });
        });

        ['mouseup', 'touchend'].forEach((event) => {
            button.addEventListener(event, () => {
                clearInterval(interval);
            });
        });
    });
}

function setButtons(state, leftWrapper, rightWrapper, interval) {
    if (state.borderCollision.left) {
        leftWrapper.classList.remove('active');
        clearInterval(interval);
    } else {
        leftWrapper.classList.add('active');
    }

    if (state.borderCollision.right) {
        rightWrapper.classList.remove('active');
        clearInterval(interval);
    } else {
        rightWrapper.classList.add('active');
    }
}
