import '../../../shared/js/utils/polyfills';

export function initFooterMenu() {
    const collapsibleElements = document.getElementsByClassName('page-footer-menu-switcher');

    for (let i = 0; i < collapsibleElements.length; i++) {
        collapsibleElements[i].addEventListener('click', function () {
            // Pokud nejde o mobil, ignorujeme
            if (window.matchMedia('(min-width: 576px)').matches) {
                return;
            }

            const target = this.closest('.footer-menu');
            target.classList.toggle('footer-menu--open');
        });
    }
}
