import { numericFormatter, removeNumericFormat } from 'react-number-format';

const formatters = {
    price: {
        decimalSeparator: ',',
        thousandSeparator: ' ',
        allowNegative: false,
        allowedDecimalSeparators: false,
        thousandsGroupStyle: 'thousand',
    },
    'int-pos': {
        decimalSeparator: ',',
        thousandSeparator: '',
        allowNegative: false,
        allowedDecimalSeparators: false,
    },
};

export const initNumberFormatInput = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + 'input[data-input-number-format]').forEach((element) => {
        setInputFormattedValue(element);

        element.addEventListener('input', (event) => {
            setInputFormattedValue(event.target);
        });
    });
};

export const setInputFormattedValue = (element, value) => {
    if (typeof value === 'undefined') {
        value = element.value;
    }

    const formatter = getFormatter(element);

    if (formatter === null) {
        return;
    }

    element.value = numericFormatter(value.replace(/[^0-9]/g, ''), formatter);
};

export const getInputNonFormattedValue = (element) => {
    const formatter = getFormatter(element);

    if (formatter === null) {
        element.value;
    }

    return parseInt(removeNumericFormat(element.value, undefined, getFormatter(element)));
};

const getFormatter = (element) =>
    element.dataset.inputNumberFormat in formatters ? formatters[element.dataset.inputNumberFormat] : null;
