import '../../../shared/js/utils/polyfills';

export function initCollapse() {
    const coll = document.getElementsByClassName('collapsible');

    for (let i = 0; i < coll.length; i++) {
        coll[i].addEventListener('click', function () {
            this.classList.toggle('active');
        });
    }
}

export function initButtonCollapse() {
    const collapsibleElements = document.getElementsByClassName('collapsible__header');

    for (let i = 0; i < collapsibleElements.length; i++) {
        collapsibleElements[i].addEventListener('click', function () {
            const target = this.closest('.collapsible');
            const callback = target.dataset.collapsibleCallback;
            const callbackOnce = target.dataset.collapsibleCallbackOnce !== undefined;

            if (callback && (!callbackOnce || target.dataset.collapsibleCallbackDone === undefined)) {
                const callbackData = target.dataset.collapsibleCallbackData;

                naja.makeRequest('GET', callback, callbackData, { history: false })
                    // eslint-disable-next-line promise/prefer-await-to-then
                    .catch((error) => {
                        console.error(error);
                    });

                if (callbackOnce) {
                    target.dataset.collapsibleCallbackDone = '';
                }
            }

            target.classList.toggle('collapsible--open');
        });
    }
}
