export function initOpenOnClickTooltips(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    // Pokud neni touch device, tooltipy se budou pozicovat na hover
    if (!window.isTouchEnabled()) {
        document.querySelectorAll(scopePrefix + '.tooltip').forEach((element) => {
            element.addEventListener('mouseenter', (event) => {
                setPosition(event.target);
            });
        });

        return;
    }

    // Pro dotykove napojime ovladani
    document.querySelectorAll(scopePrefix + '.tooltip').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();

            const target = event.currentTarget;

            if (target === null) {
                return;
            }

            // Kliknuti do otevreneho okna nic neudela
            if (event.target.closest('.tooltip__container') !== null) {
                return;
            }

            const open = target.classList.contains('tooltip--open');

            closeTooltips();

            if (open) {
                return;
            }

            target.classList.add('tooltip--open');

            setPosition(element);

            bindTooltipCloser();
        });

        element.classList.add('tooltip--open-on-click');

        const closeIcon = element.querySelector('.tooltip__close-icon');

        if (closeIcon !== null) {
            closeIcon.addEventListener('click', (event) => {
                const tooltip = event.target.closest('.tooltip');

                if (tooltip === null) {
                    return;
                }

                tooltip.classList.remove('tooltip--open');
            });
        }
    });
}
const bindTooltipCloser = () => {
    document.removeEventListener('click', tooltipCloser);
    document.addEventListener('click', tooltipCloser);
};

const tooltipCloser = () => {
    closeTooltips();
};

const closeTooltips = () => {
    document.querySelectorAll('.tooltip--open').forEach((element) => {
        element.classList.remove('tooltip--open');
    });

    document.removeEventListener('click', tooltipCloser);
};

const setPosition = (tooltip) => {
    const container = tooltip.querySelector('.tooltip__container');
    const tip = tooltip.querySelector('.tooltip__tip');

    // Nemame kontejner
    if (container === null) {
        return;
    }

    container.style.width = null;
    container.style.left = null;

    if (tip !== null) {
        tip.style.left = null;
    }

    const windowWidth = document.body.offsetWidth;
    let containerWidth = container.offsetWidth;

    // kontejner nesmi byt sirsi, nez okno
    if (containerWidth > windowWidth) {
        container.style.width = windowWidth + 'px';
        containerWidth = windowWidth;
    }

    const containerOffset = container.getBoundingClientRect().left;

    // Pripadny posun okna doleva
    let translate = Math.min(0, windowWidth - containerWidth - containerOffset);

    if (translate === 0) {
        // Pripadny posun okna doprava
        translate = Math.max(0, -containerOffset);
    }

    translate = Math.round(translate);

    // Neni potreba posun
    if (translate === 0) {
        return;
    }

    // Posunuti kontejneru -pozice stredu
    container.style.left = tooltip.offsetWidth / 2 + translate + 'px';

    // Nemame sipku
    if (tip === null) {
        return;
    }

    // Posunuti sipky -pozice stredu
    tip.style.left = containerWidth / 2 - translate + 'px';
};
