import { createDebouncedFunc } from '../../../shared/js/utils/global-helpers';

export const initTableScroll = (scope) => {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    // Inicializace pro cele okno, navaze se na zmenu velikosti okna
    if (typeof scope === 'undefined') {
        window.addEventListener(
            'resize',
            createDebouncedFunc(() => {
                document.querySelectorAll('.table-scroll-container').forEach((container) => {
                    resolveTablePosition(container);
                });
            }, 50)
        );
    }

    document.querySelectorAll(scopePrefix + '.table-scroll-container').forEach((container) => {
        resolveTablePosition(container);

        const inner = container.querySelector('.table-scroll-container__inner');

        if (inner === null) {
            return;
        }

        inner.addEventListener(
            'scroll',
            createDebouncedFunc(() => resolveTablePosition(container), 5)
        );
    });
};

const resolveTablePosition = (container) => {
    const inner = container.querySelector('.table-scroll-container__inner');

    if (inner === null) {
        return;
    }

    if (inner.scrollLeft > 0) {
        container.classList.add('table-scroll-container--of-l');
    } else {
        container.classList.remove('table-scroll-container--of-l');
    }

    if (inner.scrollWidth - inner.clientWidth > inner.scrollLeft) {
        container.classList.add('table-scroll-container--of-r');
    } else {
        container.classList.remove('table-scroll-container--of-r');
    }
};
