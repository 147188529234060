import '../../../shared/js/utils/polyfills';

export function initDisplayablePassword(scope) {
    const scopePrefix = typeof scope === 'undefined' ? '' : scope + ' ';

    document.querySelectorAll(scopePrefix + '.displayable-password__switch').forEach((element) => {
        element.addEventListener('click', (event) => {
            event.preventDefault();

            const trigger = event.currentTarget;
            const target = trigger.closest('.displayable-password').querySelector('.displayable-password__input');

            if (target === null) {
                return;
            }

            target.setAttribute('type', target.getAttribute('type') === 'password' ? 'text' : 'password');
        });
    });
}
