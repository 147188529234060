import { getJsData } from '../../../shared/js/utils/js-data';
import naja from 'naja';

const getEcomailConfigData = () => getJsData().ecomail;

export const initEcomailCartTrack = () => {
    // Ecomail neni aktivni
    if (!getEcomailConfigData().active || typeof window.ecotrack !== 'function') {
        return;
    }

    naja.addEventListener('success', (event) => {
        const cartItems = event.detail.payload.ecomailUpdatedCartItems;

        if (typeof cartItems !== 'object') {
            return;
        }

        // Odeslani udalosti se stavem kosiku
        window.ecotrack('trackUnstructEvent', {
            schema: '',
            data: {
                action: 'Basket',
                products: cartItems,
            },
        });
    });
};
