/**
 * K poslednímu zakliknutému checkboxovému filtru (např. barva -> zelená) přidá atribut data-last,
 * používá se pro odeslání posledního kliknutého filtru na server pro případ přepočítávání
 * počtu produktů pro jednotlivé filtry.
 */
export function initLastCheckbox(filterScope) {
    const sections = document.querySelectorAll(filterScope);
    sections.forEach((section) => {
        const inputs = section.querySelectorAll('input[type="checkbox"]');
        inputs.forEach((input) => {
            input.addEventListener('change', () => {
                const lastElement = document.querySelector('input[data-last]');
                if (lastElement !== null) {
                    lastElement.removeAttribute('data-last');
                }
                input.dataset.last = true;
            });
        });
    });
}

/**
 * Pokud je v formuláři nějaký input s atributem data-last, tak se vytvoří input s name="last-checked-element" a
 * pošle na server.
 */
export function handleLastClickedElement(form) {
    const lastElement = form.querySelector('input[data-last]');

    if (lastElement) {
        const payload = form.querySelector('[role="payload"]')
            ? form.querySelector('[role="payload"]')
            : form.appendChild(document.createElement('input'));

        const value = {
            name: lastElement.name,
            value: lastElement.value,
            checked: lastElement.checked,
        };
        payload.value = JSON.stringify(value);
        payload.role = 'payload';
        payload.type = 'hidden';
        payload.name = 'last-checked-element';

        form.appendChild(payload);

        lastElement.removeAttribute('data-last');
    } else {
        const payload = form.querySelector('[role="payload"]');
        if (payload) {
            payload.remove();
        }
    }
}
