const UI_BLOCKER_CSS_CLASS = 'ui-blocker';
const UI_BLOCKER_CSS_CLASS_LAYER_MODAL = 'ui-blocker--layer-modal';
const UI_BLOCKER_CSS_CLASS_LAYER_HEADER = 'ui-blocker--layer-header';
const UI_BLOCKER_SELECTOR_CONTAINER_LAYER_MODAL = '.modal';
const UI_BLOCKER_SELECTOR_CONTAINER_LAYER_HEADER = '.page-header';

export const uiBlocker = () => ({
    blockedCollection: [],
    _createBlocker: function (el) {
        // Pokud ma vyvolavajici prvek nad sebou kontejner vrstvy volime tridu vrstvz
        const layerCssClass =
            el.closest(UI_BLOCKER_SELECTOR_CONTAINER_LAYER_MODAL) !== null
                ? UI_BLOCKER_CSS_CLASS_LAYER_MODAL
                : el.closest(UI_BLOCKER_SELECTOR_CONTAINER_LAYER_HEADER) !== null
                ? UI_BLOCKER_CSS_CLASS_LAYER_HEADER
                : null;
        const rect = el.getBoundingClientRect();
        const blocker = document.createElement('div');
        blocker.classList.add(UI_BLOCKER_CSS_CLASS);
        // Nastavime pripadnou tridu vrstvy
        blocker.classList.add(layerCssClass);
        blocker.setAttribute(
            'style',
            // Pokud je trida vrstvy header, je pozice ku obrazovce -kvuli fixed pozici, jinak na dokumentu
            `left:${rect.left}px;top:${
                rect.top +
                (layerCssClass !== UI_BLOCKER_CSS_CLASS_LAYER_HEADER ? window.scrollY || window.pageYOffset : 0)
            }px;width:${rect.width}px;height:${rect.height}px`
        );
        const blockerSpinner = document.createElement('div');
        blockerSpinner.classList.add('spinner');
        blocker.appendChild(blockerSpinner);

        const renderContainer =
            'najaSpinnerRenderContainerId' in el.dataset
                ? document.getElementById(el.dataset.najaSpinnerRenderContainerId)
                : document.body;

        renderContainer.appendChild(blocker);
        this.blockedCollection.push({ el: el.getAttribute('id') || el, blocker });
    },
    _getElement: function (element) {
        return typeof element === 'string' ? document.body.querySelectorAll(element) : element;
    },
    block: function (element) {
        const el = this._getElement(element);

        if (Object.prototype.isPrototypeOf.call(NodeList.prototype, el)) {
            if (el.length === 0) {
                throw Error('Calling BLOCK on non-existing element');
            }

            el.forEach((element) => this._createBlocker(element));
        } else {
            if (!el) {
                throw Error('Calling BLOCK on non-existing element');
            }

            this._createBlocker(el);
        }

        return { unblock: () => this.unblock(el) };
    },
    unblock: function (element) {
        const el = this._getElement(element);
        const newBlockedCollection = [];
        const elementIds = [];

        if (Object.prototype.isPrototypeOf.call(NodeList.prototype, el)) {
            if (el.length === 0) {
                throw Error('Calling UNBLOCK on non-existing element');
            }

            el.forEach((element) => elementIds.push(element.getAttribute('id')));
        } else {
            if (!el) {
                throw Error('Calling UNBLOCK on non-existing element');
            }

            elementIds.push(el.getAttribute('id'));
        }

        this.blockedCollection.forEach((item) => {
            // U prvku prekreslenych AJAXem to nemusi byt isSameNode, i kdyz pro uzivatele je..
            // Je lepsi to skryt (i kdyby nahodou driv), nez to nechat viset tocit
            if (
                (typeof item.el !== 'string' && item.blocker) /* item.el.isSameNode(el)*/ ||
                elementIds.includes(item.el)
            ) {
                item.blocker.remove();
            } else {
                newBlockedCollection.push(item);
            }
        });
        this.blockedCollection = newBlockedCollection;
    },
    unblockAll: function () {
        this.blockedCollection.forEach((item) => {
            item.blocker.remove();
        });

        this.blockedCollection = [];

        // Vsechny elementy s classou ui-blocker odstranime
        const elements = document.getElementsByClassName(UI_BLOCKER_CSS_CLASS);

        for (const element of elements) {
            element.remove();
        }
    },
});
